import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';
import styles from './styles.module.scss';
import EditableText from "@/components/EditableText";
import clsx from "classnames";
import PropTypes from "prop-types";
import Link from "next/link"
import Button from "@/components/Button";
import sourceflowEnums from "@/sourceflow.enums";
import { useRouter } from 'next/router';

export default function JobCard({
    className,
    pathPrefix,
    global,
    pathURL,
    title,
    url_slug,
    addresses,
    published_at,
    categories,
    salary_package,
    expires_at,
    cta_title,
    post,

    jobs_page,
    // similar_jobs_unixtimestamp,
    // unixposttime
}) {
    const router = useRouter();
    const currentPagePathname = router.asPath;

    const job_type = categories?.map((cat) => cat.id == `${sourceflowEnums.categories.job_type.id}` && cat.values?.map((value, index) => <>{index > 0 && ", "}{value.name}</>))
    const sector = categories?.map((cat) => cat.id == `${sourceflowEnums.categories.job_sector_parent.id}` && cat.values?.map((value, index) => <><span>{value.name}</span></>))

    const post_job_type = post?.categories?.map((cat) => cat.id == `${sourceflowEnums.categories.job_type.id}` && cat.values?.map((value, index) => <>{index > 0 && ", "}{value.name}</>))
    const post_job_sector = post?.categories?.map((cat) => cat.id == `${sourceflowEnums.categories.job_sector_parent.id}` && cat.values?.map((value, index) => <><span>{value.name}</span></>))


    const cache_slug = (value) => value.toLowerCase().replace(/ /g, '-');
    const job_sector_parent = categories
        ?.map((cat) => (cat.id == `${sourceflowEnums.categories.job_sector_parent.id}` ? cat.values?.map((value) => cache_slug(value.name)) : false))
        .flat()
        .filter(Boolean);
    const post_job_sector_parent = post?.categories
        ?.map((cat) => (cat.id == `${sourceflowEnums.categories.job_sector_parent.id}` ? cat.values?.map((value) => cache_slug(value.name)) : false))
        .flat()
        .filter(Boolean);

    const job_salary_daily = categories?.map((cat) => cat.id == `${sourceflowEnums.categories.job_salary_daily.id}` && cat.values?.map((value, index) => <>{index > 0 && ", "}{value.name}</>))
    const post_job_salary_daily = post?.categories?.map((cat) => cat.id == `${sourceflowEnums.categories.job_salary_daily.id}` && cat.values?.map((value, index) => <>{index > 0 && ", "}{value.name}</>))

    // const formatDate = (dateString) => {
    //     const date = new Date(dateString);
    //     const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    //     return new Intl.DateTimeFormat('en-GB', options).format(date);
    // };


    function isISO8601(dateString) {
        const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
        return iso8601Regex.test(dateString);
    }
    function isUnixTimestamp(timestamp) {
        return !isNaN(timestamp) && timestamp.toString().length === 10;
    }

    function formatDate(expires_at) {
        if (isISO8601(expires_at)) {
            const date = new Date(expires_at);
            return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
        } else if (isUnixTimestamp(expires_at)) {
            const expiry_date = new Date(expires_at * 1000);
            const day = expiry_date.getDate().toString().padStart(2, '0');
            const month = (expiry_date.getMonth() + 1).toString().padStart(2, '0');
            const year = expiry_date.getFullYear();
            return `${day}/${month}/${year}`;
        } else {
            throw new Error("Unsupported date format");
        }
    }


    // // (Unix timestamp) - only /jobs page
    const date = new Date(expires_at * 1000);
    const formatted_expiry_date = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;



    // // Formatting the date as dd/mm/yyyy ( FOR SIMILAR JOBS )
    // const expiry_date = new Date(post?.expires_at * 1000);
    // const day = expiry_date.getDate().toString().padStart(2, '0');
    // const month = (expiry_date.getMonth() + 1).toString().padStart(2, '0');
    // const year = expiry_date.getFullYear();

    // const formattedDateSimilarJobs = `${day}/${month}/${year}`;


    // Removed trailing "dash" (-) from URL
    url_slug = url_slug?.replace(/-$/, '').concat("-").concat(addresses[0]?.split(",").shift().replaceAll(" ", "-").replaceAll(".", "").trim());
    if (post) url_slug = post.url_slug?.replace(/-$/, '').concat("-").concat(post.addresses[0]?.split(",").shift().replaceAll(" ", "-").replaceAll(".", "").trim());

    return (
        <Card className={`${clsx(className, styles.root)} ${post_job_sector_parent ? post_job_sector_parent : job_sector_parent}`}>
            <div
                className='item'
            >
                <div>
                    {sector?.length >= 1 ?
                        <div className='post-job-sector post-details mb-4 text-uppercase'>{sector}</div>
                        : ""}
                    {post_job_sector && (
                        <div className="post-job-sector post-details mb-4 text-uppercase">{post_job_sector}</div>
                    )}
                    {post?.subtitle && (
                        <div className="post-job-sector post-details mb-4 text-uppercase">{post?.subtitle}</div>
                    )}
                    <h3 className='post-title mb-4'>
                        <a target="_self" href={`${post?.link ? "" : pathURL}${url_slug ? url_slug : post?.link ? post.link : post?.url_slug}`}>{title ? title : post?.title}</a></h3>
                    <div className='d-flex gap-2 flex-wrap post-details-wrapper'>
                        {post?.short_description && (
                            <div className="post-details">{post?.short_description}</div>
                        )}
                        {/* {salary_package != null && (
                            <div className="post-package post-details">{salary_package}</div>
                        )}
                        {post?.salary_package && (
                            <div className="post-package post-details">{post.salary_package}</div>
                        )} */}
                        {jobs_page ?
                            <>
                                {addresses != "" && (
                                    <div className="post-location post-details">{addresses}</div>
                                )}
                                {/* {job_salary_daily &&
                                    <div className='post-job-salary post-details'>{job_salary_daily}</div>
                                } */}
                                {salary_package &&
                                    <div className='post-job-salary post-details'>{salary_package}</div>
                                }
                                {job_type && (
                                    <div className="post-job-type post-details">{job_type}</div>
                                )}
                                {formatted_expiry_date && (
                                    <div className="post-job-expiry post-details">
                                        Expires on {formatted_expiry_date}
                                    </div>)}
                            </> :
                            post?.expires_at && (
                                <>

                                    {jobs_page != true && (
                                        post?.addresses != "") && (
                                            <div className="post-location post-details">{post?.addresses}</div>
                                        )
                                    }
                                    {/* {post_job_salary_daily && (
                                        <div className='post-job-salary post-details'>{post_job_salary_daily}</div>
                                    )} */}
                                    {post.salary_package && (
                                        <div className='post-job-salary post-details'>{post.salary_package}</div>
                                    )}
                                    {post_job_type && (
                                        <div className="post-job-type post-details">{post_job_type}</div>
                                    )}
                                    <div className="post-job-expiry post-details">
                                        Expires on {formatDate(post.expires_at)}
                                        {/* {(similar_jobs_unixtimestamp || unixposttime) ? formattedDateSimilarJobs : formatDate(post.expires_at)} */}
                                    </div>

                                </>)}
                    </div>
                </div>
            </div>
        </Card>
    )
}
JobCard.defaultProps = {
    pathPrefix: "",
    className: "",
    cta_title: "View this job",
    pathURL: "/jobs/",
};

JobCard.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    cta_title: PropTypes.string,
    pathURL: PropTypes.string,
};